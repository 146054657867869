// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Pretendard-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Pretendard-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Pretendard-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Pretendard-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Pretendard-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Pretendard-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Pretendard-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Pretendard-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Pretendard-Thin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Pretendard Black';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: 'Pretendard Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: 'Pretendard ExtraBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: 'Pretendard ExtraLight';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: 'Pretendard Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
  font-family: 'Pretendard Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
  font-family: 'Pretendard Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}

@font-face {
  font-family: 'Pretendard SemiBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}

@font-face {
  font-family: 'Pretendard Thin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts/font.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,4CAAgC;AAClC;;AAEA;EACE,8BAA8B;EAC9B,4CAA+B;AACjC;;AAEA;EACE,mCAAmC;EACnC,4CAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,4CAAqC;AACvC;;AAEA;EACE,+BAA+B;EAC/B,4CAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,4CAAiC;AACnC;AACA;EACE,iCAAiC;EACjC,4CAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,4CAAmC;AACrC;;AAEA;EACE,8BAA8B;EAC9B,4CAA+B;AACjC","sourcesContent":["@font-face {\n  font-family: 'Pretendard Black';\n  src: url(./Pretendard-Black.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard Bold';\n  src: url(./Pretendard-Bold.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard ExtraBold';\n  src: url(./Pretendard-ExtraBold.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard ExtraLight';\n  src: url(./Pretendard-ExtraLight.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard Light';\n  src: url(./Pretendard-Light.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard Medium';\n  src: url(./Pretendard-Medium.ttf);\n}\n@font-face {\n  font-family: 'Pretendard Regular';\n  src: url(./Pretendard-Regular.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard SemiBold';\n  src: url(./Pretendard-SemiBold.ttf);\n}\n\n@font-face {\n  font-family: 'Pretendard Thin';\n  src: url(./Pretendard-Thin.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
