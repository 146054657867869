export const Quote: string = '/icons/quote.svg';
export const Heart_Pink: string = '/icons/pinkHeart.svg';
export const Heart_Purple: string = '/icons/purpleHeart.svg';
export const Notice: string = '/icons/notice.svg';
export const Bell: string = '/icons/bell.svg';
export const Alarm: string = '/icons/alarm.svg';
export const DelBtn: string = '/icons/delBtn.svg';
export const TimerIcon: string = '/icons/timer.svg';
export const MicOn: string = '/icons/micOn.svg';
export const MicOff: string = '/icons/micOff.svg';
export const EtcDots: string = '/icons/etcDots.svg';
export const VideoOn: string = '/icons/videoOn.svg';
export const VideoOff: string = '/icons/videoOff.svg';
export const RoomOut: string = '/icons/roomOut.svg';
export const Vector: string = '/icons/vector.svg';
export const Star: string = '/icons/star.svg';
export const Person: string = '/icons/person.svg';
export const Cake: string = 'icons/cake.svg';
export const Location: string = 'icons/location.svg';
export const Bag: string = 'icons/bag.svg';
export const Triangle: string = 'icons/triangle.svg';
export const Crown: string = 'icons/crown.svg';
export const Check_green: string = '/icons/check_green.svg';
export const Check_red: string = '/icons/check_red.svg';
export const Report: string = '/icons/report.svg';
export const ReportRed: string = '/icons/reportRed.svg';
export const SendMsg: string = '/icons/send.svg';
export const Out: string = '/icons/out.svg';
export const Blink: string = '/icons/blink.svg';
export const BlinkRed: string = '/icons/blink_red.svg';
export const BlinkCircle: string = '/icons/blink_circle.svg';
export const Video: string = '/icons/video.svg';
export const Warn: string = '/icons/warn.svg';
export const Down: string = '/icons/down.svg';
export const Close: string = '/icons/close.svg';
export const Coin: string = '/icons/coin.svg';
